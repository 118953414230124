import { lazy, Suspense } from "react"
import { Helmet } from "react-helmet"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { AuthProvider } from "./authentication/AuthContext"
import LoadingSpinner from "./common/loading-spinner/LoadingSpinner"
import Header from "./common/header/Header"
import FirebaseAuthActionHandler from "./authentication/FirebaseAuthActionHandler"
import RequireAuth from "./authentication/RequireAuth"
import Logout from "./authentication/Logout"
import { GOOGLE_LMFS_API_KEY } from "./config"
import { OrganisationProvider } from "./organisation-config/OrganisationConfigContext"
import ContactPage from "./contact-form/ContactPage"
import { ShipmentListContextProvider } from "./shipments/ShipmentsList/ShipmentListContext"

const ShipmentCsvUploadPage = lazy(
  () => import("./upload/ShipmentCsvUploadPage")
)
const ResetPasswordEmailPage = lazy(
  () => import("./authentication/ResetPasswordEmailPage")
)
const ResetPasswordPage = lazy(
  () => import("./authentication/ResetPasswordPage")
)
const ShipmentsList = lazy(
  () => import("./shipments/ShipmentsList/ShipmentList")
)
const GeocodeShipmentsPage = lazy(
  () => import("./geocodeAddress/GeocodeShipmentsPage")
)

const GeocodeShipmentPage = lazy(
  () => import("./geocodeAddress/GeocodeShipmentPage")
)

const GeocodeDeliveryPage = lazy(
  () => import("./geocodeAddress/GeocodeDeliveryPage")
)
const VehiclesList = lazy(() => import("./vehicles/VehiclesList"))
const PaymentsList = lazy(() => import("./payments/PaymentsList"))
const UsersList = lazy(() => import("./users/UsersList"))
const LoginPage = lazy(() => import("./authentication/LoginPage"))
const SignUpPage = lazy(() => import("./authentication/SignUpPage"))
const DriverHome = lazy(() => import("./authentication/DriverHome"))
const OrganisationConfigSettingsPage = lazy(
  () => import("./organisation-config/OrganisationConfigSettingsPage")
)
const SavedLocations = lazy(
  () => import("./saved-locations/SavedLocationsList")
)

const googleLmfsUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_LMFS_API_KEY}&libraries=journeySharing`
const script = document.createElement("script")
script.src = googleLmfsUrl
script.async = true
script.defer = true
document.body.appendChild(script)

function App() {
  return (
    <AuthProvider>
      <OrganisationProvider>
        <Router>
          <Helmet titleTemplate="%s | Deed Dashboard" defer={false} />

          <Header />

          <Suspense
            fallback={
              <div style={{ height: "calc(100vh - 112px" }}>
                <LoadingSpinner />
              </div>
            }
          >
            <Routes>
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/driver" element={<DriverHome />} />
              <Route
                path="/firebaseAuth/actionHandler"
                element={<FirebaseAuthActionHandler />}
              />
              <Route
                path="/resetpassword/email"
                element={<ResetPasswordEmailPage />}
              />
              <Route
                path="/resetpassword/password"
                element={<ResetPasswordPage />}
              />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Navigate to="/shipments" replace />
                  </RequireAuth>
                }
              />
              <Route
                path="/geocode/delivery/:deliveryId"
                element={
                  <RequireAuth>
                    <GeocodeDeliveryPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/geocode/shipment/:shipmentId/pickup"
                element={
                  <RequireAuth>
                    <GeocodeShipmentPage isForPickup={true} />
                  </RequireAuth>
                }
              />
              <Route
                path="/geocode/shipment/:shipmentId"
                element={
                  <RequireAuth>
                    <GeocodeShipmentPage isForPickup={false} />
                  </RequireAuth>
                }
              />
              <Route
                path="/geocode"
                element={
                  <RequireAuth>
                    <GeocodeShipmentsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/saved-locations"
                element={
                  <RequireAuth>
                    <SavedLocations />
                  </RequireAuth>
                }
              />
              <Route
                path="/users"
                element={
                  <RequireAuth>
                    <UsersList />
                  </RequireAuth>
                }
              />
              <Route
                path="/shipments/:shipmentId"
                element={
                  <RequireAuth>
                    <ShipmentsList />
                  </RequireAuth>
                }
              />
              <Route
                path="/shipments/upload"
                element={
                  <RequireAuth>
                    <ShipmentCsvUploadPage />
                  </RequireAuth>
                }
              />{" "}
              <Route
                path="/shipments"
                element={
                  <RequireAuth>
                    <ShipmentsList />
                  </RequireAuth>
                }
              />
              <Route
                path="/vehicles/:vehicleId/deliveries/:deliveryId"
                element={
                  <RequireAuth>
                    <VehiclesList />
                  </RequireAuth>
                }
              />
              <Route
                path="/vehicles/:vehicleId"
                element={
                  <RequireAuth>
                    <VehiclesList />
                  </RequireAuth>
                }
              />
              <Route
                path="/vehicles"
                element={
                  <RequireAuth>
                    <VehiclesList />
                  </RequireAuth>
                }
              />
              <Route
                path="/payments"
                element={
                  <RequireAuth>
                    <PaymentsList />
                  </RequireAuth>
                }
              />
              <Route
                path="/organisation-config-settings"
                element={
                  <RequireAuth requireSuper>
                    <OrganisationConfigSettingsPage />
                  </RequireAuth>
                }
              />
            </Routes>
          </Suspense>
        </Router>
      </OrganisationProvider>
    </AuthProvider>
  )
}

export default App
